import { Container } from "../../styledComponents/Container/styled";
import YoutubeEmbed from "../../features/Hooks/YoutubeEmbed";

const Video = () => {
  return (
      <>
 {/* <h1>Dragonscape jest Twoją drogą</h1> */}
 <YoutubeEmbed embedId="uE-3sZ0oork" />
      </>
  );
};

export default Video;
