import * as React from "react";
import { motion } from "framer-motion"

const contactUsSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    // x={0}
    // y={0}
    style={{
      enableBackground: "new 0 0 154 49",
    }}
    viewBox="0 0 154 49"
    {...props}
  >
    <style>
      {
        ".st1{fill:none;stroke:#fbf4e4;stroke-width:2;stroke-linecap:round;stroke-linejoin:round}.st4{fill:#fbf4e4}"
      }
    </style>
    <motion.path
      d="M4 0h146c2.2 0 4 1.8 4 4v41c0 2.2-1.8 4-4 4H4c-2.2 0-4-1.8-4-4V4c0-2.2 1.8-4 4-4z"
      style={{
        fill: "#d76460",
      }}
    />
    <motion.path  animate ={{
        scale: [1, 1.5, 1.5, 1, 1],
        borderRadius: ["0%", "0%", "50%", "50%", "0%"]
      }}
      transition={{
        duration: 1.5,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 4
      }} d="m42.2 22.5-4.3 3.4c-.8.6-1.9.6-2.7 0l-4.3-3.4" className="st1" />
    <motion.path    animate ={{
        scale: [1, 1.5, 1.5, 1, 1],
        rotate: [0, 0, 180, 180, 0],
        borderRadius: ["0%", "0%", "50%", "50%", "0%"]
      }}
      transition={{
        duration: 1.5,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 4
      }}
      d="M31.8 16.9h9.4c1.4 0 2.7.6 3.6 1.6.9 1 1.4 2.3 1.3 3.7v6.5c.1 1.4-.4 2.7-1.3 3.7S42.6 34 41.2 34h-9.4c-2.9 0-4.9-2.4-4.9-5.3v-6.5c0-2.9 2-5.3 4.9-5.3z"
      className="st1"
    />
    <path
      d="M4 .5h146c1.9 0 3.5 1.6 3.5 3.5v41c0 1.9-1.6 3.5-3.5 3.5H4C2.1 48.5.5 46.9.5 45V4C.5 2.1 2.1.5 4 .5z"
      style={{
        fill: "none",
      }}
    />
    <motion.g
      style={{
        enableBackground: "new",
      }}
    >
      <path
        d="M61.8 31.7V18.6h2v6.3h.2l5.5-6.3H72l-5.3 5.9 5.3 7.2h-2.4l-4.2-5.8-1.5 1.8v4.1h-2.1zM77.8 31.9c-.9 0-1.7-.2-2.4-.6-.7-.4-1.2-1-1.6-1.8-.4-.8-.6-1.6-.6-2.7 0-1 .2-1.9.6-2.7.4-.8.9-1.4 1.6-1.8.7-.4 1.5-.6 2.4-.6.9 0 1.7.2 2.4.6.7.4 1.2 1 1.6 1.8.4.8.6 1.7.6 2.7 0 1-.2 1.9-.6 2.7-.4.8-.9 1.3-1.6 1.8-.7.4-1.5.6-2.4.6zm0-1.6c.6 0 1.1-.2 1.5-.5.4-.3.7-.7.9-1.3s.3-1.1.3-1.7c0-.6-.1-1.2-.3-1.7s-.5-.9-.9-1.3c-.4-.3-.9-.5-1.5-.5s-1.1.2-1.5.5c-.4.3-.7.7-.9 1.3s-.3 1.1-.3 1.7c0 .6.1 1.2.3 1.7s.5.9.9 1.3.9.5 1.5.5zM86.4 25.9v5.8h-1.9v-9.8h1.8v1.6h.1c.2-.5.6-.9 1.1-1.3.5-.3 1.1-.5 1.8-.5s1.3.1 1.8.4.9.7 1.2 1.2c.3.5.4 1.2.4 2v6.2h-1.9v-6c0-.7-.2-1.3-.6-1.7-.4-.4-.9-.6-1.5-.6-.4 0-.8.1-1.2.3-.3.2-.6.5-.8.8-.2.6-.3 1-.3 1.6zM99.8 21.9v1.5h-5.4v-1.5h5.4zm-3.9-2.4h1.9v9.3c0 .4.1.6.2.8.1.2.3.3.4.4.2.1.4.1.6.1h.4c.1 0 .2 0 .3-.1l.3 1.6c-.1 0-.3.1-.5.1s-.5.1-.8.1c-.5 0-1-.1-1.4-.3-.4-.2-.8-.5-1.1-.9-.3-.4-.4-.9-.4-1.5v-9.6zM104.8 31.9c-.6 0-1.2-.1-1.7-.3s-.9-.6-1.2-1c-.3-.4-.4-1-.4-1.6 0-.6.1-1 .3-1.4.2-.4.5-.6.9-.9.4-.2.8-.4 1.2-.5s.9-.2 1.4-.2c.6-.1 1.1-.1 1.4-.2.4 0 .6-.1.8-.2.2-.1.2-.3.2-.5 0-.6-.2-1-.5-1.3-.3-.3-.8-.5-1.4-.5-.6 0-1.1.1-1.5.4-.4.3-.6.6-.8.9l-1.8-.4c.2-.6.5-1.1.9-1.4.4-.4.9-.6 1.4-.8.5-.2 1.1-.3 1.7-.3.4 0 .8 0 1.2.1.4.1.9.3 1.2.5.4.2.7.6.9 1 .2.5.4 1 .4 1.7v6.5h-1.9v-1.3h-.1c-.1.2-.3.5-.6.7-.2.2-.6.4-1 .6 0 .3-.5.4-1 .4zm.4-1.5c.5 0 1-.1 1.4-.3s.7-.5.9-.8.3-.7.3-1.1v-1.3c-.1.1-.2.1-.4.2s-.4.1-.7.1c-.2 0-.5.1-.7.1-.2 0-.4.1-.6.1-.4 0-.7.1-1 .2-.3.1-.5.3-.7.5-.2.2-.3.5-.3.8 0 .5.2.8.5 1.1.3.3.7.4 1.3.4zM112.2 31.7V18.6h1.9v13.1h-1.9zm1.7-3.3v-2.3h.3l3.9-4.2h2.3l-4.5 4.7h-.3l-1.7 1.8zm4.5 3.3-3.5-4.7 1.3-1.3 4.5 6h-2.3zM126.8 21.9v1.5h-5.4v-1.5h5.4zm-4-2.4h1.9v9.3c0 .4.1.6.2.8.1.2.3.3.4.4.2.1.4.1.6.1h.4c.1 0 .2 0 .3-.1l.3 1.6c-.1 0-.3.1-.5.1s-.5.1-.8.1c-.5 0-1-.1-1.4-.3-.4-.2-.8-.5-1.1-.9-.3-.4-.4-.9-.4-1.5v-9.6z"
        className="st4"
      />
    </motion.g>
  </svg>
)
export default contactUsSvg