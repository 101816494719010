export const skillsList = [
     "Web Design",
     "Logos",
     "Wordpress Web Sites",
  ];

  export const toLearnList = [
      "TypeScript",
      "React Native",
      "Angular",
      "Mastering Illustrator",
      "Mastering Figma",
      "한국어 (Korean language)",
  ];